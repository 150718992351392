import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: rgba(65, 62, 62, 0.63) 0px 0px 2px;
    background: white;
    border-radius: 14px;
    padding: 20px 40px;
    // width: 600px;
    margin-bottom: 30px;

    h1 {
        color: var(--blue);
        font-weight: 600;
    }
`

const TrialStep = styled.div`
    display: flex;
    flex-direction: row;

    h2 {
        font-size: 24px;
        color: var(--blue);
        font-weight: 600;

        &.trial-step-number {
            margin-right: 24px;
            border-radius: 50%;
            background-color: var(--blue);
            color: white;
            padding: 10px;
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    div {
    }
`

export function TrialInfo() {
    return (
        <Container>
            <h1>How your free trial works</h1>

            <TrialStep>
                <h2 className='trial-step-number'>1</h2>
                <div>
                    <h2>Sign up for #gifted</h2>
                    <p>You have succesfully created your account.</p>
                </div>
            </TrialStep>

            <TrialStep>
                <h2 className='trial-step-number'>2</h2>
                <div>
                    <h2>Today: Get Free Access</h2>
                    <p>Get access to all #gifted basic features for free.</p>
                </div>
            </TrialStep>

            <TrialStep>
                <h2 className='trial-step-number'>3</h2>
                <div>
                    <h2>Day 5: Trial Reminder</h2>
                    <p>We'll send you an email as a reminder. <br /> <br /> Cancel anytime in just 10 seconds.</p>
                </div>
            </TrialStep>

            <TrialStep>
                <h2 className='trial-step-number'>4</h2>
                <div>
                    <h2>Day 7: Trial Ends</h2>
                    <p>Your #gifted basic subscription starts.</p>
                </div>
            </TrialStep>
        </Container>
    );
}
